.chat-loader {
    margin: 0;
    padding: 0;
    grid-area: chat;
}
.chat-log-container {
    margin: 0;
    padding: 0;
}
.chat-log-container button {
    background: #5a1db5;
    color: #fafafa;
    border-radius: 4px;
    border: none;
    width: 125px;
    height: 30px;
}
.chat-log-container button:disabled {
    display: none;
}
.chat-log {
    display: grid;
    grid-template-columns: .25fr 1fr .25fr;
    grid-template-areas: "chat_log_name chat_log_message chat_log_time";
    padding: 5px 10px;
}
.chat-log-name {
    grid-area: chat_log_name;
}
.chat-log-message {
    grid-area: chat_log_message;
}
.chat-log-time {
    grid-area: chat_log_time;
}
.chat-label {
    grid-area: chat_label;
}
.new-chat-label {
    font-weight: bold;
}
.new-chat-textarea {
    background: #fafafa;
    font-size: 16px;
    width: 100%;
    resize: none;
    margin: 5px auto;
}
.new-chat-element button {
    background: #5a1db5;
    color: #fafafa;
    border-radius: 4px;
    border: none;
    width: 125px;
    height: 30px;
}

@media (max-width: 600px) {
    .chat-log {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        "chat_log_name chat_log_time"
        "chat_log_message chat_log_message";
    }
}