@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
/***** base styles *****/
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand";
  color: #333;
  background-color: #fafafa;
  border-radius: 6px;
  text-align: center;
}
#root {
  margin: 0 5px;
}
.core-content {
  min-height: 600px;
}
* h1 {
  font-size: 36px;
}
.delete-button {
  border: none;
}
.delete-icon {
  height: 20px;
  width: 20px;
  padding: 3px;
}
.delete-icon:hover {
  fill: #8b0000;
}
.container {
  margin: 0;
  padding: 0;
}
.frame {
  max-width: 800px;
  margin: 10px auto;
}
/***** Footer CSS *****/
.myFooter{
  display: flex;
  flex-wrap: wrap;
  background: #5a1db5;
  border-radius: 0;
  width: 100%;
  margin: 30px 0 0 0;
}
.footer-containers{
  flex-grow: 1;
  max-width: 100%;
  margin: 10px auto;
  padding: 5px 0px;
  background: #5a1db5;
}
.footer-containers a {
  text-decoration: none;
  color: #fff;
  background: none;
}
.myFooter h4 {
  color: #fff;
  background: #5a1db5;
  border-radius: 0;
}
.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px;
  }
  .navbar h1 {
    color: #5a1db5;
  }
  .navbar .links, .links2 {
    margin-left: auto;
    height: auto;
  }
  .navbar a {
    margin-left: 10px;
    text-decoration: none;
    padding: 6px;
  }
  .navbar a:hover {
    color: #5a1db5;
  }
  .nav-icon {
      height: 30px;
      width: 30px;
  }
  .nav-icon:hover {
      fill: #5a1db5;
  }
  .logged-in-button {
    height: 30px;
    width: 30px;
    fill: #588515;
  }
  .logged-out-button {
    height: 30px;
    width: 30px;
    fill: #8b0000;
  }
  
/* Less than 600px  */
@media (max-width: 600px) {
    .navbar{
      display: grid;
      grid-template-columns: 100%;
      padding: 5px;
    }
    .navbar h1 {
      flex-grow: 1;
    }
    .navbar a {
      flex-grow: 1;
      margin: 0 0 0 0;
      padding: 0px;
      flex-wrap: wrap;
      text-align: center;
    }
    .links {
      display: grid;
      /* grid-template-columns: 50% 50%; */
      grid-template-columns: 1fr 1fr 1fr;
      margin: 3px auto;
      grid-row-gap: 2px;
      row-gap: 2px;
      grid-column-gap: 2px;
      -webkit-column-gap: 2px;
              column-gap: 2px;
      width: 100%;
    }
    .linksL {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 3px auto;
        grid-row-gap: 2px;
        row-gap: 2px;
        grid-column-gap: 2px;
        -webkit-column-gap: 2px;
                column-gap: 2px;
        width: 100%;
      }
  }
/*Account Summary and first bar containers below ... */
.acctSummary{
    border: 2px solid #5a1db5;
    padding: 10px;
    background: #f7f7f7;
    height: minmax(125px, content);
    margin: 0;
  }
.acctSummary * {
    background: none;
}


/* Less than 800px  */
@media (max-width: 800px) {
}
.kpi-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  grid-row-gap: 5px;
  row-gap: 5px;
}
.kpiTile{
  border: 2px solid #5a1db5;
  padding: 10px 0;
  flex-grow: 1;
  background: #f7f7f7;
  grid-template-rows: 1fr 1fr 20px;
  line-height: 100%;
}
.kpiTile * {
  background: none;
}
.kpiTile a{
  text-decoration: none;
  display: grid;
}
.kpiTile:hover {
  cursor: pointer;
  background: #f4eefc;
}
.kpi-icons{
  fill: #5a1db5;
  height: 45px;
  width: 45px;
  padding: 10px;
}
/* Less than 800px  */
@media (max-width: 800px) {

  .frame {
    min-width: 100%;
    max-width: 100%;
  }
  .content{
    max-width: 100%;
    margin: 2%;
  }
  .kpi-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .kpiTile a {
    padding: 0;
    grid-template-rows: 30px 1fr 20px;
    height: 100%;
    line-height: 100%;
    margin: 0;
   }
  .acct-container{
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 5px;
    row-gap: 5px;
  }
}

@media (max-width: 325px) {
  .kpi-container {
    display: grid;
    grid-template-columns: 100%;
  }
}
.maintenance {
    padding: 10px;
    margin: 5px auto;
    max-width: 800px;
}
.new-request{
    border: solid 2px #5a1db5;
    background: #ededed;
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    flex-grow: 1;
    min-width: 300px;
}
.new-request a {
    text-decoration: none;
    background: none;
}
.new-request h3{
    background: none;
    border-radius: 4px;
}
.new-request:hover{
    cursor: pointer;
}
.emergency-req {
    border: solid 2px #8b0000;
    margin: 5px;
    background: #F9ECEC;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    flex-grow: 1;
    min-width: 300px;
}
.emergency-req a {
    text-decoration: none;
    background: none;
}
.emergency-req h3 {
    background: none;
}
.emergency-req:hover {
    cursor: pointer;
}
.open-requests {
    padding: 0;
    margin: 15px auto;
}
.open-requests a {
    text-decoration: none;
}
.open-requests a:hover {
    cursor: pointer;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
    background: #eee;
}
.open-requests a:hover * {
    background: none;
}
.open-requests h2 {
    margin: 10px;
    -webkit-text-decoration: wavy;
            text-decoration: wavy;
}
.open-requests button {
    background: #5a1db5;
    color: #fafafa;
    border-radius: 4px;
    border: none;
    width: 125px;
    height: 30px;
  }
.open-requests button:disabled {
    display: none;
  }
.request-preview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        "address address"
        "category priority"
        "quote quote-approval"
        "description description";
    padding: 8px 3px;
    border: solid 2px #5a1db5;
    margin: 10px auto;
    background: #ededed;
}
.request-preview:hover {
    cursor: pointer;
    background: #f4eefc;
}
.request-preview * {
    background: none;
}
.preview-address{
    grid-area: address;
}
.preview-category {
    grid-area: category;
 }
.preview-priority {
    grid-area: priority;
}
.preview-quote {
    grid-area: quote;
}
.preview-quote-approval {
    grid-area: quote-approval;
}
.preview-description {
    grid-area: description;
}
.request-header h4{
    margin-left: 5px;
}
.request-body p {
    text-align: left;
    margin-left: 10px;
}

/* request detail css */
/*  maintenance detail readout css */
.maintenance-detail{
    max-width: 1000px;
    height: minmax(800px, content);
    margin: auto;
}
.group {
    display: grid;
    background: #eee;
    grid-row-gap: 5px;
    margin: 10px 0;
    padding: 15px 5px;
    border: solid 2px #5a1db5;
    grid-template-columns: .5fr 1fr .5fr 1fr;
}
.group * {
    background: none;
}
.group p {
    text-align: left;
}
.group h3 {
    margin-left: 0;
    margin-right: auto;
}
.element {
    display: flex;
    margin: auto;
}
* button:hover {
    cursor: pointer;
}
.approval-button {
    width: 75px;
    height: 40px;
    background: #5a1db5;
    color: #fafafa;
    border: solid 1px #aaa;
}
.reject-button {
    width: 75px;
    height: 40px;
    background: #fafafa;
    color: #8b0000;
    border: solid 1px #aaa;
}
.inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
/***** request frame *****/
.new-req-summary {
    grid-template-areas: 
    "address-label address address address"
    "tenant_label tenant tenant_phone_label tenant_phone"
    "owner_label owner owner_phone_label owner_phone"
    "issue_label issue issue issue"
    "priority_label priority created_label created"
    "description_label description description description"
    "new_button new_button new_button new_button";
 }
.address-label {
    grid-area: address-label;
}
.address {
    grid-area: address;
}
.tenant-label {
    grid-area: tenant_label;
}
.tenant {
    grid-area: tenant;
}
.tenant-phone-label {
    grid-area: tenant_phone_label;
}
.tenant-phone {
    grid-area: tenant_phone;
}
.owner-label {
    grid-area: owner_label;
}
.owner {
    grid-area: owner;
}
.owner-phone-label {
    grid-area: owner_phone_label;
}
.owner-phone {
    grid-area: owner_phone;
}
.issue-label {
    grid-area: issue_label;
}
.issue {
    grid-area: issue;
}
.priority-label {
    grid-area: priority_label;
}
.priority {
    grid-area: priority;
}
.created-label {
    grid-area: created_label;
}
.created {
    grid-area: created;
}
.description {
    grid-area: description;
}
.description-label {
    grid-area: description_label;
}
.new-button {
    grid-area: new_button;
    margin: auto;
}
/***** quote frame *****/
.quote-summary {
    grid-template-areas:
    "contact-label contact contact_phone_label contact_phone"
    "quote_label quote quote_approval_label quote_approval"
    "quote_notes_label quote_notes quote_notes quote_notes"
    "quote_button quote_button quote_button quote_button"
    "quote_form quote_form quote_form quote_form";
}
.contact {
    grid-area: contact;
}
.contact-phone-label {
    grid-area: contact_phone_label;
}
.contact-phone {
    grid-area: contact_phone;
}
.quote-label {
    grid-area: quote_label;  
}
.quote {
    grid-area: quote;
}
.quote-approval {
    grid-area: quote_approval;
}
.quote-notes-label {
    grid-area: quote_notes_label;
}
.quote-notes {
    grid-area: quote_notes;
}
.quote-button {
    grid-area: quote_button;
    margin: auto;
}
.quote-form {
    grid-area: quote_form;
    display: flex;
    flex-wrap: wrap;
}
.quote-form input {
    background: #fafafa;
    resize: none;
    flex-grow: .5;
    height: 20px;
}
/***** solution frame *****/
.solution-summary {
    grid-template-areas:
    "solution_date_label solution_date solution_approval_label solution_approval"
    "solution_pic_label solution_pic planned_date_label planned_date"
    "solution_notes_label solution_notes solution_notes solution_notes"
    "solution_button solution_button solution_button solution_button";
}
.solution-date-label {
    grid-area: solution_date_label;
}
.solution-date {
    grid-area: solution_date;
}
.solution-approval-label {
    grid-area: solution_approval_label;
}
.solution-approval {
    grid-area: solution_approval;
}
.solution-pic-label {
    grid-area: solution_pic_label;
}
.solution-pic {
    grid-area: solution_pic;
    margin-left: 5px;
    margin-right: auto;
}
.planned-date-label {
    grid-area: planned_date_label;
}
.planned-date {
    grid-area: planned_date;
}
.solution-notes-label {
    grid-area: solution_notes_label;
}
.solution-notes {
    grid-area: solution_notes;
}
.solution-button {
    grid-area: solution_button;
    margin: auto;
}
.chat {
    grid-template-areas: 
    "chat chat chat chat";
}
.new-chat {
    grid-template-areas: 
    "new_chat new_chat new_chat new_chat";
}
.new-chat-element {
    grid-area: new_chat;
    width: 90%;
    margin: 0 auto;
}
/***** Create request css *****/
.createMaintenance {
    margin: 10px auto;
}
.createMaintenance h2 {
    color: #5a1db5;
}
.createMaintenance button {
    background: #5a1db5;
}
.create {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}
.create label {
    text-align: left;
    display: block;
}
.create h2 {
    font-size: 20px;
    color: #5a1db5;
    margin-bottom: 30px;
}
.create input, .create textarea, .create select {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: block;
}
.create button {
    background: #5a1db5;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}
.checkbox {
    display: flex;
    flex-wrap: wrap;
}
.checkbox-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}
.create-frame {
    background: #eee;
}
/* Less than 600 px screen width */
@media (max-width: 600px) {
    .group {
        grid-template-columns: .5fr 1fr ;
    }
    .new-req-summary {
        grid-template-areas: 
        "address-label address-label"
        "address address"
        "tenant_label tenant" 
        "tenant_phone_label tenant_phone"
        "owner_label owner"
        "owner_phone_label owner_phone"
        "issue_label issue" 
        "priority_label priority"
        "created_label created"
        "description_label description_label"
        "description description"
        "new_button new_button";
     }
    .quote-summary {
        grid-template-areas:
        "contact_label contact"
        "contact_phone_label contact_phone"
        "quote_label quote"
        "quote_approval_label quote_approval"
        "quote_notes_label quote_notes_label"
        "quote_notes quote_notes"
        "quote_button quote_button"
        "quote_form quote_form";
    }
    .solution-summary {
        grid-template-areas:
        "solution_date_label solution_date"
        "solution_approval_label solution_approval"
        "solution_pic_label solution_pic"
        "planned_date_label planned_date"
        "solution_notes_label solution_notes_label"
        "solution_notes solution_notes"
        "solution_button solution_button";
        } 
}
/***** under 600px width *****/
@media (max-width: 600px) {
    .request-preview {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
            "address"
            "category" 
            "priority"
            "quote" 
            "quote-approval"
            "description";
    }
}
.chat-loader {
    margin: 0;
    padding: 0;
    grid-area: chat;
}
.chat-log-container {
    margin: 0;
    padding: 0;
}
.chat-log-container button {
    background: #5a1db5;
    color: #fafafa;
    border-radius: 4px;
    border: none;
    width: 125px;
    height: 30px;
}
.chat-log-container button:disabled {
    display: none;
}
.chat-log {
    display: grid;
    grid-template-columns: .25fr 1fr .25fr;
    grid-template-areas: "chat_log_name chat_log_message chat_log_time";
    padding: 5px 10px;
}
.chat-log-name {
    grid-area: chat_log_name;
}
.chat-log-message {
    grid-area: chat_log_message;
}
.chat-log-time {
    grid-area: chat_log_time;
}
.chat-label {
    grid-area: chat_label;
}
.new-chat-label {
    font-weight: bold;
}
.new-chat-textarea {
    background: #fafafa;
    font-size: 16px;
    width: 100%;
    resize: none;
    margin: 5px auto;
}
.new-chat-element button {
    background: #5a1db5;
    color: #fafafa;
    border-radius: 4px;
    border: none;
    width: 125px;
    height: 30px;
}

@media (max-width: 600px) {
    .chat-log {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        "chat_log_name chat_log_time"
        "chat_log_message chat_log_message";
    }
}
