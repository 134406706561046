/*Account Summary and first bar containers below ... */
.acctSummary{
    border: 2px solid #5a1db5;
    padding: 10px;
    background: #f7f7f7;
    height: minmax(125px, content);
    margin: 0;
  }
.acctSummary * {
    background: none;
}


/* Less than 800px  */
@media (max-width: 800px) {
}