@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/***** base styles *****/
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand";
  color: #333;
  background-color: #fafafa;
  border-radius: 6px;
  text-align: center;
}
#root {
  margin: 0 5px;
}
.core-content {
  min-height: 600px;
}
* h1 {
  font-size: 36px;
}
.delete-button {
  border: none;
}
.delete-icon {
  height: 20px;
  width: 20px;
  padding: 3px;
}
.delete-icon:hover {
  fill: #8b0000;
}
.container {
  margin: 0;
  padding: 0;
}
.frame {
  max-width: 800px;
  margin: 10px auto;
}
/***** Footer CSS *****/
.myFooter{
  display: flex;
  flex-wrap: wrap;
  background: #5a1db5;
  border-radius: 0;
  width: 100%;
  margin: 30px 0 0 0;
}
.footer-containers{
  flex-grow: 1;
  max-width: 100%;
  margin: 10px auto;
  padding: 5px 0px;
  background: #5a1db5;
}
.footer-containers a {
  text-decoration: none;
  color: #fff;
  background: none;
}
.myFooter h4 {
  color: #fff;
  background: #5a1db5;
  border-radius: 0;
}