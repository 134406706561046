.kpi-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
}
.kpiTile{
  border: 2px solid #5a1db5;
  padding: 10px 0;
  flex-grow: 1;
  background: #f7f7f7;
  grid-template-rows: 1fr 1fr 20px;
  line-height: 100%;
}
.kpiTile * {
  background: none;
}
.kpiTile a{
  text-decoration: none;
  display: grid;
}
.kpiTile:hover {
  cursor: pointer;
  background: #f4eefc;
}
.kpi-icons{
  fill: #5a1db5;
  height: 45px;
  width: 45px;
  padding: 10px;
}
/* Less than 800px  */
@media (max-width: 800px) {

  .frame {
    min-width: 100%;
    max-width: 100%;
  }
  .content{
    max-width: 100%;
    margin: 2%;
  }
  .kpi-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .kpiTile a {
    padding: 0;
    grid-template-rows: 30px 1fr 20px;
    height: 100%;
    line-height: 100%;
    margin: 0;
   }
  .acct-container{
    display: grid;
    grid-template-columns: 100%;
    row-gap: 5px;
  }
}

@media (max-width: 325px) {
  .kpi-container {
    display: grid;
    grid-template-columns: 100%;
  }
}